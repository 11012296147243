import * as React from 'react'
import Layout from '../components/Layout'
import astraeus_bg from '../images/astraeus_bg.jpg'


export default function AstraeusPage() {
    return (
        <Layout title='Astraeus | Conex Research'>
            <div className='h-fit lg:h-screen w-screen'>
                <img src={astraeus_bg} className='w-full h-full object-contain sm:object-cover'/>
            </div>
        </Layout>
    )
}
